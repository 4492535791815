//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-532:_7592,_6348,_3824,_2724,_3016,_1792,_1988,_960;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-532')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-532', "_7592,_6348,_3824,_2724,_3016,_1792,_1988,_960");
        }
      }catch (ex) {
        console.error(ex);
      }