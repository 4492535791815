export default {
  BUTTON_PRIMARY_CLASS: 'button btn btn-primary',
  BUTTON_SECONDARY_CLASS: 'button btn btn-default',
  LOAD_MORE_BUTTON_CLASS: 'btn btn-primary',

  PRODUCT_REPEATER_CLASS: 'product-items product-items-3',

  FACET_BUTTON_CLASS: '',
  CURRENT_SEARCH: 'Current Search',
  START_OVER: 'Clear All',

  SEARCH_BOX_INPUT_CLASS: 'search-text form-control',
  SEARCH_BOX_BUTTON_CLASS: 'btn btn-default',
  SEARCH_BOX_PLACEHOLDER: 'Search by part or interchange number',
};
